import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import printJS from 'print-js'
const Index = {
  name: 'view-ordera',
  components: {
    PageHeader
  },
  mixins: [tableMixin],
  data() {
    return {
      formSearch: {
        KeyWords: ''
      },
      params: {}
    }
  },
  mounted() {
    this.params = this.$route.params.row
    console.log(this.params)
  },
  methods: {
    Adsearch() {
    },
    async getTableDataAsync() {
      if (!this.params.DeliveryId) return
      const response = await this.$api.QueryDeliveryDetailsInfoByDeliveryId({
        DeliveryId: this.params.DeliveryId
      })
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(this.tableData)
      return response
    },
    // 打印
    print() {
      this.printHTML('testTable')
      // printJS({
      //   printable: 'testTable', // DOM id
      //   type: 'html',
      //   scanStyles: false
      // })
    },
    printHTML(id) {
      const html = document.querySelector('#' + id).innerHTML
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      // document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children[0].children.re
      // console.log(document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children)
      // 删除最后一项
      // const tr_arr = document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children
      // for (const i in tr_arr) {
      //   console.log(tr_arr[i].children)
      //   if (tr_arr[i].children) {
      //     tr_arr[i].removeChild(tr_arr[i].children[tr_arr[i].children.length - 1])
      //   }
      // }
      // 提取第一个表格的内容 即表头
      const ths = div.querySelectorAll('.el-table__header-wrapper th')
      const ThsTextArry = []
      for (let i = 0, len = ths.length; i < len; i++) {
        if (ths[i].innerText !== '' && ths[i].innerText !== '操作') ThsTextArry.push(ths[i].innerText)
      }

      // 删除多余的表头
      div.querySelector('.hidden-columns').remove()
      // 第一个表格的内容提取出来后已经没用了 删掉
      div.querySelector('.el-table__header-wrapper').remove()

      // 将第一个表格的内容插入到第二个表格
      let newHTML = '<tr style="text-align: center; border:1px solid #ccc" >'
      for (let i = 0, len = ThsTextArry.length; i < len; i++) {
        newHTML += '<td style="text-align: center; font-weight: bold; padding:5px 0;border:1px solid #ccc ">' + ThsTextArry[i] + '</td>'
      }
      newHTML += '</tr>'
      div.querySelector('.el-table__body-wrapper table').insertAdjacentHTML('afterbegin', newHTML)
      // 将新的 DIV 添加到页面 打印后再删掉
      // document.querySelector('body').appendChild(div)

      printJS({
        printable: printDOMID,
        type: 'html',
        scanStyles: false,
        style: '.mid_info {display:flex} .el-table__body-wrapper table {border-collapse:collapse} .el-table__body-wrapper table td{ border:1px solid #ccc; text-align: center }' // 表格样式
      })
      div.remove()
    },
    // 出库 更新出库单 () => $router.push({name: 'delivery-fwarehouse'})
    EditOrderStockOut() {
      if (this.tableData.length <= 0) return this.$message.error('没有物料')
      if (this.params.SelectType === 1) {
        this.$api.EditOrderStockOut({
          DeliveryID: this.params.DeliveryId,
          OrderID: this.params.OrderId,
          Reamrks: ''
        }).then(res => {
          console.log(res)
          if (res.RetCode === '0' && res.Message) {
            //  StockOutType: this.$route.params.StockOutType,
          // StockOutId: this.$route.params.StockOutId
            return this.$router.push({ name: 'delivery-fwarehouse', params: { StockOutType: 0, StockOutId: res.Message }})
          }
          return this.$message.error('错误：' + res.RetMsg)
        })
        return
      }
      return this.$router.push({ name: 'delivery-fwarehouse', params: { StockOutType: 0, StockOutId: this.params.StockOutId }})
    }
  }
}
export default Index
