<template>
  <div class="view-order">
    <PageHeader title="商品出库" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div class="mid_view m-lr-5">
        <div class="left f14 min-border-right select_none">收货单位</div>
        <div class="mid1  f14 el-1 p-left-5">{{ params.BuyCompanyName }}</div>
      </div>
      <div class="mid_view m-lr-5">
        <div class="left f14 min-border-right select_none">送货地址</div>
        <div class="mid1 f14 el-1 p-left-5">{{ params.SendAddress }}</div>
      </div>
      <div class="ModelBttton-blue-30" @click="EditOrderStockOut">
        出库发货
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        id="testTable"
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 330px)"
        row-key="id"
        border
      >
        <el-table-column
          align="center"
          prop="MaterialInvCode"
          label="物料编号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialName"
          align="center"
          label="物料名称"
          show-overflow-tooltip
        />
        <el-table-column
          prop="BrandName"
          label="品牌"
          width="150"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialType"
          label="物料类型"
          show-overflow-tooltip
        />
        <el-table-column
          prop="Specification"
          label="规格"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          prop="StockUnitN"
          label="单位"
          show-overflow-tooltip
        />
        <el-table-column
          align="center"
          prop="MaterialLotNum"
          label="批号/序列号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialLotNo"
          label="入库批次"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="MaterialValidDate"
          label="效期"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ $minCommon.setDateFormate(scope.row.MaterialValidDate) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="OrderNum"
          label="订购数量"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="DeliveryDetailsNum"
          label="出库数量"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="DINum"
          label="DI码"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="StorageCondition"
          label="运输条件"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="StockCode"
          label="所属仓库"
          align="center"
          show-overflow-tooltip
        />
      </el-table>
      <div class="pagination-container flex j-between">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
        <el-button size="mini" icon="el-icon-printer" @click="print">打印</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Index from '@/minxin/management/view-order.js'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>

